<template>
  <div class="page-container">
    <v-card class="subdivision-statistics-card card d-flex" min-height="712.5px">
      <v-tabs class="statistic-tabs" v-model="tab">
        <v-tab key="product-statistics" href="#product-statistics">Статистика по товарам</v-tab>
        <v-tab key="points-flow" href="#points-flow">Расход бонусов</v-tab>
        <v-tab key="balance-statistics" href="#balance-statistics">Статистика по балансу</v-tab>
      </v-tabs>
      <v-tabs-items touchless class="statistic-tabs-items" v-model="tab">
        <v-tab-item class="product-statistics-tab-item" key="" id="product-statistics">
          <product-statistics-tab/>
        </v-tab-item>
        <v-tab-item class="points-flow-tab-item" key="points-flow" id="points-flow">
           <points-flow-tab/>
        </v-tab-item>
        <v-tab-item class="balance-statistics-tab-item" key="balance-statistics" id="balance-statistics">
          <subdivision-balance-statistics-tab/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ProductStatisticsTab from "./tabs/ProductStatisticsTab";
import PointsFlowTab from "./tabs/PointsFlowTab";
import SubdivisionBalanceStatisticsTab from "./tabs/SubdivisionBalanceStatisticsTab";

export default {
  name: "structuralSubdivision",
  components: {
    ProductStatisticsTab,
    PointsFlowTab,
    SubdivisionBalanceStatisticsTab
  },
  data: () => ({
    tab: null
  })
}
</script>

<style lang="sass" scoped>
.page-container
  height: 100%

.statistic-tabs
  min-width: 600px
  flex: 0 1 auto
  width: auto
  margin-top: 20px
  margin-left: 20px

.statistic-tabs-items
  min-width: 600px !important

.product-statistics-tab-item, .points-flow-tab-item, .balance-statistics-tab-item
  padding: 0 25px
</style>