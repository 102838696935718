<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="product-statistics-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end">
                            <datepicker
                                :default-show="defaultShow"
                                :start-date-input-settings="startDateInputSettings"
                                :value="value"
                                :end-date-input-settings="endDateInputSettings"
                                @change="dateChangeHandler"
                            />
                            <button
                                @click="clearDates"
                                title="Clear"
                                type="button"
                                class="k-button k-button-icon k-clear-button-visible"
                            >
                                <span class="k-icon k-i-filter-clear"></span>
                            </button>
                        </div>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapActions, mapGetters } from 'vuex'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'
    import getSubdivisionProductOrderStatisticsReport from '@/api/entities/orders'
    import { DateRangePicker } from '@progress/kendo-vue-dateinputs'

    export default {
        name: 'ProductStatisticsTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            datepicker: DateRangePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData()
            await this.fetchSubdivisionListShort()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                defaultShow: false,
                value: {
                    start: null,
                    end: null,
                },
                startDateInputSettings: {
                    label: 'От',
                    format: 'dd.MM.yyyy',
                },
                endDateInputSettings: {
                    format: 'dd.MM.yyyy',
                    label: 'До',
                },
                sort: [{ field: 'date', dir: 'desc' }],
                columns: [
                    {
                        field: 'date',
                        title: 'Дата заказа',
                        minWidth: 450,
                        width: '450px',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                        filterCell: 'date_filter',
                    },
                    {
                        field: 'structural_subdivision_name',
                        title: 'Структурное подразделение',
                        minWidth: 300,
                        width: '300px',
                        type: 'text',
                    },
                    {
                        field: 'position_name',
                        title: 'Товар',
                        minWidth: 300,
                        width: '300px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'position_quantity',
                        title: 'Количество в пачке',
                        minWidth: 150,
                        width: '150px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'quantity',
                        title: 'Количество товара',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                ],
            }
        },
        methods: {
            fetchData: api.orders.getSubdivisionProductOrderStatistics,
            ...mapActions('structuralsubdivisions', [
                'fetchSubdivisionListShort',
            ]),
            selectSubdivision({ value }) {
                if (value.id) {
                    this.setFilter('structural_subdivision_id', 'eq', value.id)
                } else {
                    this.removeFilter('structural_subdivision_id')
                }

                this.updateTable(true)
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            dateChangeHandler({ value }) {
                this.value.start = value.start
                this.value.end = value.end
                if (value.start || value.end) {
                    const dateStart = new Date(value.start).toLocaleDateString(
                        'ru-RU'
                    );
                    const dateEnd = new Date(value.end).toLocaleDateString(
                        'ru-RU'
                    );
                    if (dateEnd === '01.01.1970') {
                        this.setFilter('date', 'gte', dateStart);
                    } else if (dateStart === dateEnd) {
                        this.removeFilter('date');
                        this.setFilter('date', 'gtedatetime', dateStart);
                    } else {
                        this.removeFilter('date');
                        const obj = [
                            {
                                field: 'date',
                                operator: 'gte',
                                value: dateStart + 'T00:00:00',
                            },
                            {
                                field: 'date',
                                operator: 'lte',
                                value: dateEnd + 'T23:59:59',
                            },
                        ];
                        this.setMultipleFilter('date', obj);
                    }
                } else {
                    this.removeFilter('dealer_id');
                }
                this.updateTable(true);
            },
            clearDates() {
                this.value.start = null
                this.value.end = null
                this.clearFilters()
                this.updateTable(true);
            },
            async downloadReport() {
                try {
                    const response =
                        await api.orders.getSubdivisionProductOrderStatisticsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Статистика заказов по товарам структурных подразделений.xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
        },
    }
</script>

<style lang="sass" scoped>
    .product-statistics-table
      margin-top: 20px
</style>
