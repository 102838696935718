var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"product-statistics-table",attrs:{"resizable":true,"pageable":_vm.gridPageable,"sortable":true,"filterable":true,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"subdivision_user_type_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.SubdivisionUserTypes,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectSubdivisionUserType}})]}},{key:"subdivision_user_name_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.subdivisionUsersListShort,"text-field":'name',"data-item-key":'id',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectSubdivisionUser}})]}},{key:"product_order_status_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.OrderStatuses,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectStatus}})]}},{key:"delivery_type_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.DeliveryTypes,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectDeliveryType}})]}},{key:"actions",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('CButton',{staticClass:"actions-btn btn-square btn-info",attrs:{"disabled":!dataItem.can_cancel_order},on:{"click":function($event){return _vm.cancelOrder(dataItem.product_order_id)}}},[_c('i',{staticClass:"la la-remove"}),_vm._v(" Отменить ")])],1)])]}},{key:"subdivision_user_type",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSubdivisionUserTypeByKey( dataItem.structural_subdivision_user_type ))+" ")])]}},{key:"product_order_status",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getProductOrderStatusNameByKey( dataItem.product_order_status ))+" ")])]}},{key:"delivery_type",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getDeliveryTypeNameByKey(dataItem.delivery_type))+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mt-3 mb-2"},[_c('v-col',{staticClass:"pl-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('CButton',{staticClass:"export-excel-btn",attrs:{"color":"success"},on:{"click":_vm.downloadReport}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_c('span',[_vm._v("Экспорт в Excel")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }