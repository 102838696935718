<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="product-statistics-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template
                        v-slot:subdivision_user_type_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="SubdivisionUserTypes"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectSubdivisionUserType"
                        />
                    </template>
                    <template
                        v-slot:subdivision_user_name_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="subdivisionUsersListShort"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectSubdivisionUser"
                        />
                    </template>
                    <template
                        v-slot:product_order_status_filter="{ methods, props }"
                    >
                        <dropdownlist
                            class="categories-filter"
                            :data-items="OrderStatuses"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template v-slot:delivery_type_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="DeliveryTypes"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectDeliveryType"
                        />
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="
                                        cancelOrder(dataItem.product_order_id)
                                    "
                                    :disabled="!dataItem.can_cancel_order"
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-remove"></i>
                                    Отменить
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template
                        v-slot:subdivision_user_type="{ props: { dataItem } }"
                    >
                        <td>
                            {{
                                getSubdivisionUserTypeByKey(
                                    dataItem.structural_subdivision_user_type
                                )
                            }}
                        </td>
                    </template>
                    <template
                        v-slot:product_order_status="{ props: { dataItem } }"
                    >
                        <td>
                            {{
                                getProductOrderStatusNameByKey(
                                    dataItem.product_order_status
                                )
                            }}
                        </td>
                    </template>
                    <template v-slot:delivery_type="{ props: { dataItem } }">
                        <td>
                            {{
                                getDeliveryTypeNameByKey(dataItem.delivery_type)
                            }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapActions, mapGetters } from 'vuex'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'
    import SubdivisionUserTypes from '@/config/subdivision_user_types'
    import OrderStatuses from '@/config/order_statuses'
    import DeliveryTypes from '@/config/delivery_types'
    import { showConfirm } from '@/lib/swal2'
    import { showMessage } from '@/lib/toasted'

    export default {
        name: 'PointsFlowTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData()
            await this.fetchSubdivisionUserListShort()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 0,
                gridPageable: { pageSizes: true },
                sort: [{ field: 'date', dir: 'desc' }],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 160,
                        width: '160px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'structural_subdivision_user_type',
                        title: 'Тип пользователя',
                        filterCell: 'subdivision_user_type_filter',
                        cell: 'subdivision_user_type',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'structural_subdivision_user_name',
                        title: 'ФИО',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                    },
                    {
                        field: 'structural_subdivision_user_email',
                        title: 'Е-маил',
                        type: 'text',
                        filter: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'product_order_status',
                        title: 'Статус',
                        filterCell: 'product_order_status_filter',
                        cell: 'product_order_status',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'expense',
                        title: 'Расход ',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'income',
                        title: 'Возврат',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'delivery_type',
                        title: 'Тип доставки',
                        filterCell: 'delivery_type_filter',
                        cell: 'delivery_type',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'date',
                        title: 'Дата',
                        filter: 'date',
                        type: 'date',
                        minWidth: 200,
                        width: '200px',
                    },
                ],
                SubdivisionUserTypes,
                OrderStatuses,
                DeliveryTypes,
            }
        },
        methods: {
            fetchData:
                api.points_flow_items.getPointsFlowItemsSubdivisionStatistics,
            ...mapActions('points_flow_items', [
                'fetchSubdivisionUserListShort',
            ]),
            getSubdivisionUserTypeByKey(key) {
                const type = SubdivisionUserTypes.find(item => item.key === key)
                return type ? type.name : null
            },
            getDeliveryTypeNameByKey(key) {
                const type = DeliveryTypes.find(item => item.key === key)
                return type ? type.name : null
            },
            async cancelOrder(id) {
                const { isConfirmed } = await showConfirm(
                    'Вы уверены, что хотите отменить данный заказ?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.orders.cancelSubdivisionProductOrder(id)
                    showMessage(status, message)
                }

                await this.getData()
            },
            selectSubdivisionUserType({ value }) {
                if (value.key) {
                    this.setFilter(
                        'structural_subdivision_user_type',
                        'eq',
                        value.key
                    )
                } else {
                    this.removeFilter('structural_subdivision_user_type')
                }

                this.updateTable(true)
            },
            selectSubdivisionUser({ value }) {
                if (value.id) {
                    this.setFilter(
                        'structural_subdivision_user_id',
                        'eq',
                        value.id
                    )
                } else {
                    this.removeFilter('structural_subdivision_user_id')
                }

                this.updateTable(true)
            },
            selectDeliveryType({ value }) {
                if (value.key) {
                    this.setFilter('delivery_type', 'eq', value.key)
                } else {
                    this.removeFilter('delivery_type')
                }

                this.updateTable(true)
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('product_order_status', 'eq', value.key)
                } else {
                    this.removeFilter('product_order_status')
                }

                this.updateTable(true)
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            async downloadReport() {
                try {
                    const response =
                        await api.statistics.getSubdivisionStatisticsPointsFlowItemsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        )

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement('a')

                    fileLink.href = fileURL
                    fileLink.setAttribute(
                        'download',
                        `Расход бонусов(структурное подразделение).xlsx`
                    )
                    document.body.appendChild(fileLink)

                    fileLink.click()
                } catch (e) {
                    console.warn(e)
                }
            },
        },
        computed: {
            ...mapGetters('points_flow_items', ['subdivisionUsersListShort']),
            ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
        },
    }
</script>

<style lang="sass" scoped>
    .product-statistics-table
      margin-top: 20px

    .actions-btn
      padding: 3px 10px !important

      &.btn::after
        content: none !important
</style>
