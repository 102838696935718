<template>
  <v-container class="subdivision-balance-statistics-container pr-0 pb-0 mr-0 ml-0">
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <h5>
          Период
        </h5>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <v-menu
            ref="menu_from"
            v-model="menu_from"
            :close-on-content-click="false"
            :return-value.sync="date_from"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="shrink"
                :value="computeDate(date_from)"
                label="Дата от"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              locale="ru-RU"
              v-model="date_from"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu_from = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="dateFromChange"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex flex-grow-1">
        <h5>
          Структурные подразделения
        </h5>
      </div>
      <div class="d-flex">
        <v-checkbox v-model="selectAll" @change="changeAllCheckboxes" class="subdivision-checkbox" label="Выбрать всех"></v-checkbox>
      </div>
    </div>
    <div class="d-flex flex-column subdivisions-list">
      <div class="d-flex flex-row subdivisions-list__item" v-for="(subdivision, key) in subdivisionListShort" :key="key">
        <div class="d-flex flex-grow-1 dealer-name-column">
          {{ subdivision.name }}
        </div>
        <div class="d-flex dealer-checkbox-column">
          <v-simple-checkbox  :value="selectedSubdivisions[subdivision.id]" @input="changeCheckbox(subdivision.id, $event)" class="subdivision-checkbox"></v-simple-checkbox>
        </div>
      </div>
    </div>
    <v-row class="mt-3 mb-2">
      <v-col cols="12" class="pl-0 d-flex justify-end">
        <CButton @click="generateReport" :disabled="isDisabled" class="export-excel-btn" color="success">
          <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
        </CButton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
import api from "@/api";
import {toDataSourceRequestString} from "@progress/kendo-data-query";

export default {
  name: "GeneralStatisticsTab",
  async mounted() {
    this.fetchSubdivisionListShort();
  },
  data: () => ({
    date_from: null,
    menu_from: false,
    selectAll: false,
    selectedSubdivisions: {},
  }),
  methods: {
    ...mapActions('structuralsubdivisions', ['fetchSubdivisionListShort']),
    computeDate(date) {
      if (!date) return null

      return moment(date).format("DD.MM.YYYY")
    },
    changeAllCheckboxes(value) {
      if (value) {
        this.selectedSubdivisions = this.allSubdivisionIds;
      } else {
        this.selectedSubdivisions = {};
      }
    },
    changeCheckbox(subdivisionId, value) {
      const selectedCheckboxes = {...this.selectedSubdivisions};

      if (value) {
        selectedCheckboxes[subdivisionId] = value
      } else {
        delete selectedCheckboxes[subdivisionId];
      }

      this.selectedSubdivisions = selectedCheckboxes;

      if (!value) {
        this.selectAll = false;
      }
    },
    dateFromChange() {
      this.$refs.menu_from.save(this.date_from);
    },
    async generateReport() {
      try {
        const {data} = await api.structural_subdivisions.subdivisionProductsReport({
          date_from: this.date_from,
          subdivisions: Object.keys(this.selectedSubdivisions)
        });

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;

        const dateFrom = moment(this.date_from).format("DD.MM.YYYY");

        fileLink.setAttribute('download', `Статистика по балансу за ${dateFrom}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (e) {
        console.warn(e);
      }
    }
  },
  computed: {
    ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
    allSubdivisionIds() {
      const subdivisionIds = {};

      this.subdivisionListShort.forEach(({id}) => {
        subdivisionIds[id] = true;
      });

      return subdivisionIds;
    },
    isDisabled() {
      return Object.keys(this.selectedSubdivisions).length === 0 || !this.date_from;
    }
  }
}
</script>

<style lang="sass" scoped>
.subdivision-balance-statistics-container
  max-width: 100% !important
  padding-left: 4px

.subdivisions-list
  &__item
    padding: 5px 0 5px 10px

.subdivision-checkbox::v-deep
  margin-top: 0
  padding-top: 0

  .v-icon
    &:before
      font-size: 30px !important

  .v-label
    order: 1
    margin-right: 6px

  .v-input--selection-controls__input
    order: 2
</style>